<template>
  <div class="app-container search-result-container">
    <div class="container_media">
      <vf-breadcrumb :data="breadData"></vf-breadcrumb>
    </div>
    <div class="title">
      <div>搜索结果</div>
      <p>SEARCH RESULTS</p>
    </div>
    <!-- 搜索结果提示 -->
    <div class="result-tips container_media">
      找到与<a v-if="params.label_id">标签<a v-if="params.label_name"><a>【{{ params.label_name
      }}】</a></a></a><a v-if="params.keyword_name && params.keyword_name !== ''">"{{ typeof
  (params.keyword_name) == "string" ? params.keyword_name : params.keyword_name.join(",") }}"</a>相关的
      <a class="el-button el-button--link-primary">{{ type | transitionValToLabel(goodsTypes)}}({{ resultTotal }}条)</a>

      <div class="play_cont" @click="handlePlayAll(2)" v-if="type !== 4 && type !== 5 && resultList.length > 0">
        <img v-if="!playing" src="@/assets/image/result_playBtn.png" alt="">
        <ul v-if="playing" class="pause">
          <li class="beat"></li>
          <li class="beat"></li>
          <li class="beat"></li>
          <li class="beat"></li>
        </ul>
        <span>播放全部</span>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="container_media" :class="{ music: resultList.length > 0 && type === 2 }">
      <div v-if="type === 2 && resultList.length">
        <!-- 音乐列表 -->
        <music-list :isSimilar="false" :listLoading="loading" :list="resultList"></music-list>
        <!-- 分页器 -->
        <Pagination style="margin-top: 50px;" :hiddenPage="true" :total="resultTotal" :limit="params.limit || 12"
          :page.sync="params.page || 1" :hidden-page="false" @pagination="handleChangePackage" />
      </div>

      <!-- 音乐列表 -->
      <vf-music-list v-if="false" :type="type" v-loading="loading" ref="music-list" :data="resultList" :limit="params.limit || 12"
        :keywordName="params.keyword_name ? params.keyword_name : params.label_name" :page.sync="params.page || 1"
        :total="resultTotal" :position="'center'" @pagination="handleChangePackage" :resultSpecialMark="true">
        <template>
          <!-- <VfMusicItem v-if="type === 2" :data="scope"
            :keywordName="params.keyword_name ? params.keyword_name : params.label_name"
            :buttons="['cartBtn', 'collect', 'share', 'download']" /> -->
          <!-- <sound-effect-card
            v-if="type === 3"
            class="sound-effect-card-container"
            :data="scope"
            :keywordName="params.keyword_name ? params.keyword_name : params.label_name"
            :buttons="['cart', 'collect', 'share', 'download']"
          /> -->
          <div class="video-item-container">
            <!-- <video-card :data="scope" :keywordName="params.keyword_name ? params.keyword_name : params.label_name"
              :collected="scope.is_collected" @onClick="gotoVideoDetail(scope.object_id)" /> -->
          </div>
        </template>
        <template slot="empty">
        </template>
      </vf-music-list>
      <div v-loading="loading">
        <div class="main" v-if="type == 4 && resultList.length">
          <div class="video-cards">
            <div class="video-card" v-for="data in resultList" :key="data.object_id">
              <div class="video-items">
                <VideoCard :video="data || {}" @download="handleDownload(data)" @hover="handleHover"/>
                <div class="title-info">
                  <p class="name" :class="{'hober-v': data.object_id == hoverVideoId}">{{ data.name }}</p>
                  <!-- <img v-if="data.is_show_hot === 1" :src="require(`@/assets/svg/${ orderNo == 2 && data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)" /> -->
                </div>
              </div>
            </div>
          </div>
          <Pagination :hiddenPage="true" :total="resultTotal" :limit.sync="params.limit" :page.sync="params.page"
            @pagination="handleChangePackage" />
        </div>
      </div>

      <div class="container_media" v-if="type === 3 && resultList.length">
        <sound-effect-card :list="resultList" :pageQuery="{}" />
        <Pagination style="margin-top: 50px;" :hiddenPage="true" :total="resultTotal" :limit="params.limit || 12"
          :page.sync="params.page || 1" :hidden-page="false" @pagination="handleChangePackage" />
      </div>
      <div v-if="type === 5 && resultList.length">
        <pictureList class="pictureList" :list="resultList" @nextPage="nextPage"
          :endPage="Math.ceil(resultTotal / params.limit || 12) === params.page" />
        <Pagination style="margin-top: 50px;" :hiddenPage="true" :total="resultTotal" :limit="params.limit || 12"
          :page.sync="params.page || 1" :hidden-page="false" @pagination="handleChangePackage" />
      </div>
      <div class="search-empty-container" v-if="!resultList.length && !loading">
        <div class="empty-img">
          <img src="~@/assets/svg/empty-search-result.svg" alt="">
        </div>

        <div class="empty-container">
          <p class="empty-text">没有搜索到您想要的素材？</p>
          <p class="empty-operation-text">请 <span>更换搜索词</span> 或者直接联系 <button class="service empty-btn"
              @click="handleService">在线客服</button> 一对一选曲</p>
          <p class="empty-service-text">客服电话：<span>135-2235-2793</span>（微信同步） </p>
          <div class="qrcode-container">
            <div class="img">
              <img src="~@/assets/image/guide/wechat-icon.png" alt="">
            </div>
            <p class="qrcode-tips">扫码添加客服微信</p>
          </div>
        </div>
      </div>
      <!-- <div v-else class="search-empty-container">
        <div class="empty-img">
          <img src="~@/assets/svg/empty-search-result.svg" alt="">
        </div>

        <div class="empty-container">
          <p class="empty-text">没有搜索到您想要的素材？</p>
          <p class="empty-operation-text">请 <span>更换搜索词</span> 或者直接联系 <button class="service empty-btn" @click="handleService">在线客服</button> 一对一选曲</p>
          <p class="empty-service-text">客服电话：<span>135-2235-2793</span>（微信同步） </p>
          <div class="qrcode-container">
            <div class="img">
              <img src="~@/assets/image/guide/wechat-icon.png" alt="">
            </div>
            <p class="qrcode-tips">扫码添加客服微信</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { fetchMusicList, fetchSearchWordList } from "@/api/music";
import { GOODS_TYPE } from "@/enum/common";
import VfMusicList from "@/components/VfMusicList";
// import SoundEffectCard from "@/components/SoundEffectCard";
import SoundEffectCard from "@/components/member/effectCard";
// import VideoCard from "@/components/VideoCard";
import VideoCard from '@/views/limitExempt/components/videoCard.vue'
import Buttons from "@/layout/mixins/Buttons";
import Pagination from '@/components/Pagination'
import pictureList from '@/views/picture/components/pictureList.vue'
import MusicList from '@/views/music/components/MusicList.vue'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'
import { HeaderMenuList } from '@/config'

export default {
  name: "SearchResult",
  components: { VideoCard, SoundEffectCard, VfMusicList, Pagination, pictureList, MusicList, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '图片', path: '/'},
        {breadcrumbName: 'name'}
      ],
      HeaderMenuList,
      goodsTypes: [...Object.values(GOODS_TYPE)],
      resultList: [],
      resultTotal: 0,
      label_id: [],
      hoverVideoId: '',
      loading: true
    };
  },
  computed: {
    playing() {
      return !!this.$store.state.music.isPlaying
    },
    params() {
      let { page = 1, limit = 12, keyword_name = "", type } = this.$route.query;
      if (type === "4") {
        limit = 20;
      }
      return {
        ...this.$route.query,
        keyword_name,
        page: Number(page),
        limit: Number(limit)
      };
    },
    type() {
      const type = this.params.type || 2;
      return Number(type);
    }
  },
  mixins: [Buttons],
  created() {
    if (this.$route.query.more) {
      this.label_id = window.sessionStorage.getItem("tagId");
      this.getMusicList();
    } else {
      this.getSearchResultList();
    }
  },
  mounted() {
    let menu = HeaderMenuList.filter(i=>i.type == this.type)
    this.breadData[2].breadcrumbName = '“'+this.$route.query.keyword_name+'”'
    this.breadData[1].breadcrumbName = menu.length && menu[0].name || '音乐'
    this.breadData[1].path = menu.length && menu[0].path || '/music'

  },
  methods: {
    handleHover(id){
      this.hoverVideoId = id
    },
    nextPage() {
      this.params.page++
      this.getSearchResultList();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    handleService() {
      window.qimoChatClick();
    },
    /**
     * 页数发生改变
     * 2021-12-23 18:05:00
     * @author SnowRock
     * @param page
     * @param limit
     */
    handleChangePackage({ page, limit }) {
      this.$router.push({
        query: {
          ...this.params,
          page,
          limit
        }
      });
      this.reload();
    },
    async getMusicList() {
      let { page = 1, limit = 12, more } = this.$route.query;
      let params = {
        page: Number(page),
        limit: Number(limit),
        more: Number(more),
        label_id: this.label_id
      };
      this.loading = true
      const res = await fetchMusicList(params);
      // console.log(res)
      const { data } = res;
      this.resultList = data.list.map((i) => {
        return {
          ...i,
          is_collected: i.is_collect
        };
      });
      console.log(this.resultList);
      this.resultTotal = data.total;
      this.loading = false;
    },
    /**
     * 播放全部
     */
    async handlePlayAll(type) {
      await this.$store.dispatch("music/updateOrderList", this.resultList);
      await this.handlePlayerAll(this.resultList[0], type); // 限制播放音乐/音效试听播放次数
    },
    /**
     * 获取搜索结果页面信息
     * 2021-12-23 17:37:03
     * @author SnowRock
     */
    getSearchResultList() {
      const { params } = this;
      if (this.type === 3) {
        params.limit = 20
      }
      if(this.type === 5) {
        params.limit = 50
      }
      fetchSearchWordList({
        ...params
      }).then((res) => {
        if (res.code == 0) {
          const { data } = res;
          this.resultList = data.list.map((i) => {
            return {
              ...i,
              labels: i.label,
              is_collected: i.is_collect
            };
          });
          this.resultTotal = data.total;
          this.loading = false;
        }
      });
    },
    /**
     * 跳转到视频详情页
     * @param {String} videoId 视频id
     */
    async gotoVideoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId);

      if (!result) return;
      this.$router.push(`/video/detail?videoId=${videoId}`);
    }
  }
};
</script>

<style lang="scss" scoped>
$max-width: 1200px;
.title {
  margin-top: 30px;
  font-size: 36px;
  @include center-middle-flex($justify: center);
  flex-direction: column;
  font-weight: 500;
  div{
    color: #333333;
    line-height: 50px;
  }
  p {
    font-weight: 400;
    margin-top: 8px;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 20px;
    color: #C3C3CD;
  }
}
.pause{
  width: 10px;
  //width: 10px;
  height: 8px;
  overflow: hidden;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 4px;
  .beat{
    width: 1.5px;
    height: 8px;
    background: #fff;
    -webkit-animation: beat-move .5s ease-out infinite alternate;
    &:first-of-type{
      animation-delay: .3s;
    }
    &:nth-of-type(2){
      animation-delay: 0s;
    }
    &:nth-of-type(3){
      animation-delay: .375s;
    }
    &:nth-of-type(4){
      animation-delay: .25s;
    }
  }
  @keyframes beat-move {
    0%{
      -webkit-transform: translateY(100%);
    }
    25%{
      -webkit-transform: translateY(75%);
    }
    50%{
      -webkit-transform: translateY(50%);
    }
    75%{
      -webkit-transform: translateY(25%);
    }
    100%{
      -webkit-transform: translateY(0);
    }
  }
}
.center {
  width: $max-width;
  margin: 0 auto;
}

.search-result-container {
  padding-top: 84px;
  padding-bottom: 100px;
  min-height: calc(100vh - 268px);
  background-color: #fff;

  .result-tips {
    // @extend .center;
    padding-top: 30px;
    padding-bottom: 40px;
    font-weight: 400;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    line-height: 22px;
    align-items: center;

    /deep/ {
      .el-button--link-primary {
        font-weight: 400!important;
        font-size: 16px;
        color: #E52A0D;
      }
    }

    .play_cont {
      margin-left: 21px;
      width: 105px;
      height: 32px;
      background: linear-gradient(180deg, #F4561E 0%, #E52B0D 100%);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 16px
      }

      span {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        padding-left: 6px;
      }
    }
  }

  .result-main {
    // @extend .center;
    padding-bottom: 64px;

    &.music {
      background: #ffffff;
      // box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
      border-radius: 12px;
      padding: 0 33px;
      padding-bottom: 64px;

      .music-table {
        line-height: 64px;
        @include font-bold($size: $font-size-md,
          $color: rgba($font-minor-color, 0.8));
        border-bottom: 2px solid rgba(195, 195, 205, 0.36);
        display: flex;
        margin-bottom: 19px;

        .music-title {
          width: 525px;
        }

        .music-tags {
          width: 225px;
        }

        .music-time {}
      }

      .sound-effect-card-container {
        width: calc((100% / 3) - 7px);
        margin-bottom: 10px;
        display: inline-block;
        margin-right: 10px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }

    /deep/ {
      .sound-effect-card-container {
        width: calc((100% / 4) - 9px);
        margin-bottom: 10px;
        display: inline-block;
        margin-right: 10px;

        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }

      .video-item-container {
      }

      .music-item-container {
        .tag {
          width: 123px;
          min-width: 123px;
          white-space: nowrap;
        }

        .detail {
          width: 300px;
        }
      }
    }
  }
}
.main {
  width: 100%;
  @include center-middle-flex;
  flex-direction: column;
  // padding: 0 0 80px;
  position: relative;
  .video-cards {
    margin-bottom: 36px;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-gap: 0px 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .video-card{
      width: 23.5%;
      margin-right: 2%;
      // margin-bottom: 20px;
      cursor: pointer;
      &:nth-child(4n+4) {
        margin-right: 0;
      }
    }
    .video-items {
      @include size(100%, 80%);
      border-radius: 4px;
      margin-bottom: 20px;
      .title-info{
        @include center-middle-flex($justify: flex-start);
      }
      .name {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 12px
      }
      img {
        @include size(28px, 20px);
        margin-left: 8px;
      }
      .hober-v {
        color: #E52A0D;
      }
      video {
        @include size(100%, 100%);
        object-fit: fill;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
      }

      .coverimg {
        @include size(100%, 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
      }

      .loading {
        display: inline-block;
        font-size: 30px;
        position: absolute;
        top: 45%;
        left: 45%;
        color: #fff;
      }
    }
  }
}

.search-empty-container {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
  border-radius: 12px;
  padding-top: 60px;
  text-align: center;
  padding-bottom: 110px;


  .empty-img {
    img {
      width: 160px;
      height: 140px;
      margin-bottom: 12px;
    }
  }

  .empty-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 30px;
  }

  .empty-operation-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    margin-bottom: 24px;

    span {
      color: #E52A0D;
    }

    .empty-btn {
      width: 74px;
      height: 24px;
      border-radius: 5px;
      border: 1px solid #E52A0D;
      outline: none;
      background: #fff;
      color: #E52A0D;
      cursor: pointer;
    }
  }

  .empty-service-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    margin-bottom: 12px;

    span {
      font-weight: bold;
    }
  }

  .qrcode-container {
    img {
      width: 120px;
      height: 120px;
      margin-bottom: 8px;
    }

    .qrcode-tips {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
    }
  }
}
</style>
